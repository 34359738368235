import { useMutation, useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckPermission from "../../components/CheckPermission";
import DeleteModal from "../../components/DeleteModal";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { deleteItem, getItems, exportCategoryExcel, downloadItemExcel, exportItemExcel, handleImportCatergory } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { toastAlert } from "../../utils/SweetAlert";
import FileModal from "../../components/FileModal";
import { downloadFile } from "../../helper/helper";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";

const ItemsList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["item-list", page, limit],
    queryFn: async () => {
      const resp = await getItems(page, limit, details?.companyUID);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteItem(id, details?.companyUID),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });


  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) =>
      handleImportCatergory(formData, domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleAction = async (data) => {
    try {
      if (data?.type === constant.FILE_ACTION.IMPORT) {
        const formData = new FormData();
        formData.append("file", data?.file);
        fileUploadMutation.mutate({ formData, domain: details?.companyUID });
      } else {
        const domain = details?.companyUID;
        const response =
          data?.type === constant.FILE_ACTION.EXPORT
            ? await exportItemExcel(domain)
            : await downloadItemExcel(domain);

        downloadFile(response?.data, `${domain}_item.xlsx`);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setShow(false);
    }
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        {/* <Row className="align-items-center">
          <Col xl={6}>
            <h2 className="mainhead font-24">{t("item.items")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme me-2"  onClick={() => {
                              if (!data?.items?.length) {
                                toastAlert("error", "Category not found to export");
                                return;
                              }
                              setShow({
                                company: details?.companyUID,
                                type: constant.FILE_ACTION.EXPORT,
                              });
                            }}>
              {t("globals.export")}
            </button>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_UPLOAD_AND_EDIT_ITEMS}
            >
              <Link className="userBtn btn-theme" to="../add-item">
                {t("globals.addNew")}
              </Link>
            </CheckPermission>
          </Col>
        </Row> */}


        <Row>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="mainhead font-24">{t("item.items")}</h2>

            <div className="d-flex align-items-center gap-2">
              <button
                className="userBtn btn-theme me-2 p-3"
                onClick={() => {
                  setShow({
                    company: details?.companyUID,
                    type: constant.FILE_ACTION.DOWNLOAD,
                  });
                }}
                title="Download Sample"
              >
                <FaDownload />
              </button>

              <label
                className="userBtn btn-theme me-2 p-3"
                role="button"
                htmlFor="import-catergory"
                title="Import"
              >
                <FaFileImport />
              </label>
              <button
                className="userBtn btn-theme me-2 p-3"
                title="Export"
                onClick={() => {
                  if (!data?.items?.length) {
                    toastAlert("error", "Item not found to export");
                    return;
                  }
                  setShow({
                    company: details?.companyUID,
                    type: constant.FILE_ACTION.EXPORT,
                  });
                }}
              >
                <FaFileExport />
              </button>
              <input
                type="file"
                className="d-none"
                id="import-catergory"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => {
                  if (
                    e.target.files[0]?.type !==
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ) {
                    toastAlert("error", t("errorMsg.xlsxFormat"));
                    return;
                  }
                  setShow({
                    company: details?.companyUID,
                    type: constant.FILE_ACTION.IMPORT,
                    file: e.target.files[0],
                  });
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
              <Link className="userBtn btn-theme" to="../add-item">
                {t("globals.addNew")}
              </Link>
            </div>
          </div>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("item.itemName")}</th>
                <th>{t("item.itemNameSpanish")}</th>
                <th>{t("item.category")}</th>
                <th>{t("item.applyToLocation")}</th>
                <th>{t("item.itemSellPrice")}</th>
                <th>{t("item.expiration")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.items && data?.items?.length > 0 ? (
                data?.items?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.en_name}</td>
                      <td>{item?.es_name ? item?.es_name : "N/A"}</td>
                      <td>
                        {" "}
                        {i18next?.language == "es"
                          ? item?.categoryDetails?.es_name
                          : item?.categoryDetails?.en_name}
                      </td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                      <td>{item?.additionalInfo?.sellingPrice}</td>
                      <td>
                        <p className="greenBox">{`${item?.expirationTime?.days}d:${item?.expirationTime?.hours}h:${item?.expirationTime?.mins}m`}</p>
                      </td>
                      <td>
                        <Link to={`../item-details/${item?._id}`}>
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <CheckPermission
                          permission={
                            constant.PERMISSIONS.CAN_UPLOAD_AND_EDIT_ITEMS
                          }
                        >
                          <Link
                            to={`../add-item?id=${item?._id}`}
                            role="button"
                          >
                            <img src="/images/Edit.svg" alt="" />
                          </Link>
                          <img
                            role="button"
                            src="/images/Delete.svg"
                            className="img-fluid actionIcon"
                            onClick={() => setShowDelete(item?._id)}
                          />
                        </CheckPermission>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("item.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.items?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
      <FileModal
        show={show}
        handleClose={() => setShow(false)}
        handleAction={handleAction}
      />
    </div>
  );
};

export default ItemsList;
