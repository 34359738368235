import { useIsFetching } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import "./authentication/auth.css";
import Enterotp from "./authentication/Enterotp";
import Forget from "./authentication/Forget";
import Login from "./authentication/Login";
import Resetpassword from "./authentication/Resetpassword";
import Loader from "./components/Loader/Loader";
import NotFound from "./components/NotFound";
import PanelRoutes from "./routes/PanelRoutes";
import { PublicAuth, UserAuth } from "./utils/ProtectedRoute";
import ScrollTop from "./utils/ScrollTop";
import { messaging } from "./firebase/firebaseConfig";
import { getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";

function App() {
  const isFetching = useIsFetching(); 
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_APP_VAPID_KEY,
      });
      localStorage.setItem("fcm", token);
      onMessage(messaging, (payload) => {
        const { title, body } = payload.notification;
        new Notification(title, {
          body,
        });
      });
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="*" element={<NotFound />} />

        {/*********************Admin Routes*********************/}
        <Route path="*" element={<UserAuth />}>
          <Route path="*" element={<PanelRoutes />} />
        </Route>

        {/*********************All Public Routes*********************/}
        <Route path="*" element={<PublicAuth />}>
          <Route path="" element={<Login />} />
          <Route path="forget" element={<Forget />} />
          <Route path="otp" element={<Enterotp />} />
          <Route path="reset-password/:id" element={<Resetpassword />} />
        </Route>
      </Routes>
      {!!isFetching && <Loader />}
    </>
  );
}

export default App;
