import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import NotFound from "../components/NotFound";
import AddCategory from "../pages/Datamanagement/AddCategory";
import AddItem from "../pages/Datamanagement/AddItem";
import AddNewTask from "../pages/Datamanagement/AddNewTask";
import AddReceivingList from "../pages/Datamanagement/AddReceivingList";
import AddnewPreplist from "../pages/Datamanagement/AddnewPreplist";
import CategoryList from "../pages/Datamanagement/CategoryList";
import CompleteTaskdetail from "../pages/Datamanagement/CompleteTaskdetail";
import ItemDetails from "../pages/Datamanagement/ItemDetails";
import ItemsList from "../pages/Datamanagement/ItemsList";
import PrepList from "../pages/Datamanagement/PrepList";
import ProductList from "../pages/Datamanagement/ProductList";
import ReceivingList from "../pages/Datamanagement/ReceivingList";
import TaskList from "../pages/Datamanagement/TaskList";
import UserDetails from "../pages/Datamanagement/UserDetails";
import ChooseSize from "../pages/LabelManagement/ChooseSize";
import CreateLabel from "../pages/LabelManagement/CreateLabel";
import LabelManagement from "../pages/LabelManagement/LabelManagement";
import Orderindex from "../pages/Order&Transactions/Orderindex";
import PrinterManagement from "../pages/PrinterManagement/PrinterManagement";
import Addproduct from "../pages/Product/Addproduct";
import Reportsindex from "../pages/Reports&Analytics/Reportsindex";
import AddNewUser from "../pages/Usermanagement/AddNewUser";
import Userdetail from "../pages/Usermanagement/Userdetail";
import Usermanagement from "../pages/Usermanagement/Usermanagemnt";
import AddNewCompany from "../pages/companyManagement/AddNewCompany";
import BrandDetails from "../pages/companyManagement/BrandDetails";
import CompanyDetails from "../pages/companyManagement/CompanyDetails";
import CompanyLocation from "../pages/companyManagement/CompanyLocation";
import CompanyLocationDetails from "../pages/companyManagement/CompanyLocationDetails";
import CompanyManagement from "../pages/companyManagement/CompanyManagement";
import Dashboard from "../pages/dashboard/Dashboard";
import EditProfile from "../pages/dashboard/EditProfile";
import MyProfile from "../pages/dashboard/MyProfile";
import NewNotification from "../pages/notificationAndNews/NewNotification";
import Notification from "../pages/notificationAndNews/Notification";
import PaymentAndBilling from "../pages/paymentAndBilling/PaymentAndBilling";
import TicketDetails from "../pages/supportTickets/TicketDetails";
import { CheckPermission } from "../utils/ProtectedRoute";
import { constant } from "../utils/constants";
import "./routes.css";

const PanelRoutes = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />   

      {/************************User Management**********************/}
      <Route path="/usermanagement" element={<Usermanagement />} />
      <Route path="/userdetail/:id" element={<Userdetail />} />
      <Route
        path=""
        element={
          <CheckPermission
            permission={constant.PERMISSIONS.CAN_ADD_NEW_USERS}
          />
        }
      >
        <Route path="addNewUser" element={<AddNewUser />} />
      </Route>

      {/*****************************User Profile********************/}
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/editprofile" element={<EditProfile />} />

      {/*****************************Items************************/}
      <Route path="/items" element={<ItemsList />} />
      <Route path="/add-item" element={<AddItem />} />
      <Route path="/item-details/:id" element={<ItemDetails />} />

      {/*****************************Product**********************/}
      <Route path="/product" element={<ProductList />} />
      <Route path="/addproduct" element={<Addproduct />} />

      {/****************************Prep List**********************/}
      <Route path="/prep-list" element={<PrepList />} />
      <Route path="/add-prep-list" element={<AddnewPreplist />} />

      {/****************************Task List************************/}
      <Route path="/tasklist" element={<TaskList />} />
      <Route path="/addnewtask" element={<AddNewTask />} />
      <Route path="/task-details/:id" element={<CompleteTaskdetail />} />

      {/****************************Receiving List********************/}
      <Route path="/receivinglist" element={<ReceivingList />} />
      <Route path="/addreceivinglist" element={<AddReceivingList />} />

      {/****************************Category**********************/}
      <Route path="/categorylist" element={<CategoryList />} />
      <Route path="/addcategory" element={<AddCategory />} />

      {/***************************Label managment*******************/}
      <Route path="/label" element={<LabelManagement />} />
      <Route path="/choose-size" element={<ChooseSize />} />
      <Route path="/create-label" element={<CreateLabel />} />

      {/***************************Billing and Payments*******************/}
      <Route path="/paymentandbilling" element={<PaymentAndBilling />} />
      <Route path="/transactions" element={<Orderindex />} />

      {/**********************Notification and Reports*****************/}
      <Route path="/reportsindex" element={<Reportsindex />} />
      <Route path="/notifications" element={<Notification />} />
      <Route path="/new-notification" element={<NewNotification />} />

      {/***************************Printer managment*******************/}
      <Route path="/printers" element={<PrinterManagement />} />



      <Route path="/ticketDetails" element={<TicketDetails />} />
      {/* <Route path="/userlist" element={<UserList />} /> 
      As the user list and user management have the same data so user management component is called here */}
      <Route path="/userlist" element={<Usermanagement />} />
      <Route path="/userdetails" element={<UserDetails />} />
      <Route path="/companymanagement" element={<CompanyManagement />} />
      <Route path="/addnewcompany" element={<AddNewCompany />} />
      <Route path="/companylocation/:header" element={<CompanyLocation />} />
      <Route path="/companydetails/:header" element={<CompanyDetails />} />
      <Route path="/brand/:id/:header" element={<BrandDetails />} />
      <Route
        path="/location/:id/:header"
        element={<CompanyLocationDetails />}
      />

      <Route
        path="/not-allowed"
        element={<NotFound text={t("globals.notAllowed")} />}
      />
      <Route path="*" element={<NotFound text={t("globals.notFound")} />} />
    </Routes>
  );
};
export default PanelRoutes;
