import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { deleteDataList, downloadDataListExcel, exportDataListExcel, getDataList, handleImportDataList } from "../../services/services";
import { UploadIcon } from "../../SvgIcons/allIcons";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import i18next from "i18next";
import CheckPermission from "../../components/CheckPermission";
import { downloadFile } from "../../helper/helper";
import FileModal from "../../components/FileModal";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";

const PrepList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const details = useDetails();
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit],
    queryFn: async () => {
      const resp = await getDataList(
        page,
        limit,
        search,
        constant.DATA_LIST.PREP_LIST,
        details?.companyUID,
        true
      );
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteDataList(id, details?.companyUID),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

    const fileUploadMutation = useMutation({
      mutationFn: ({ formData, domain }) =>
        handleImportDataList(formData, domain, constant.DATA_LIST.PREP_LIST),
      onSuccess: (resp) => {
        toastAlert("success", resp?.data?.message);
        refetch();
      },
    });

  
    const handleAction = async (data) => {
      try {
        if (data?.type === constant.FILE_ACTION.IMPORT) {
          const formData = new FormData();
          formData.append("file", data?.file);
          fileUploadMutation.mutate({ formData, domain:details?.companyUID });
        } else {
          const domain = details?.companyUID;
          const response =
            data?.type === constant.FILE_ACTION.EXPORT
              ? await exportDataListExcel(domain, constant.DATA_LIST.PREP_LIST)
              : await downloadDataListExcel(domain, constant.DATA_LIST.PREP_LIST);
  
          downloadFile(response?.data, `${domain}_prepList.xlsx`);
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setShow(false);
      }
    };

  const [file, setFile] = useState(null);

  // const onDrop = useCallback((acceptedFiles) => {
  //   if (acceptedFiles && acceptedFiles.length > 0) {
  //     const uploadedFile = acceptedFiles[0];
  //     const fileUrl = URL.createObjectURL(uploadedFile);
  //     setFile(fileUrl);
  //   }
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("prepList.prepList")} </h2>
          <div className="d-flex align-items-center gap-2">
            {/* <CompanyDropDown setCompany={setCompany} company={company} /> */}
            <button
              className="userBtn btn-theme me-2"
              onClick={() => {
                setShow({company: details?.companyUID, type: constant.FILE_ACTION.DOWNLOAD });
              }}
              title="Download Sample"
            >
              <FaDownload />
            </button>

            <label
              className="userBtn btn-theme me-2"
              role="button"
              htmlFor="import-catergory"
              title="Import"
            >
              <FaFileImport />
            </label>
            <button
              className="userBtn btn-theme me-2"
              title="Export"
              onClick={() => {
                if (!data?.datalists?.length) {
                  toastAlert("error", "Category not found to export");
                  return;
                }
                setShow({ company: details?.companyUID, type: constant.FILE_ACTION.EXPORT });
              }}
            >
              <FaFileExport />
            </button>
            <input
              type="file"
              className="d-none"
              id="import-catergory"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => {
                if (
                  e.target.files[0]?.type !==
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                  toastAlert("error", t("errorMsg.xlsxFormat"));
                  return;
                }
                setShow({
                  company: details?.companyUID,
                  type: constant.FILE_ACTION.IMPORT,
                  file: e.target.files[0],
                });
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <Link
              className="userBtn btn-theme"
              to="../add-prep-list"
             
            >
              {t("globals.addNew")}
            </Link>
          </div>
        </div>

        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("prepList.productionName")}</th>
                <th>{t("prepList.items")}</th>
                <th>{t("prepList.qunatityLabel")}</th>
                <th>{t("prepList.labelProfile")}</th>
                <th>{t("prepList.prepLocation")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.itemDetails?.en_name}</td>
                      <td>{item?.details?.labelQuantity}</td>
                      <td>
                        {item?.details?.labelProfile
                          ? item?.details?.labelProfile
                          : "N/A"}
                      </td>
                    
                      <td>
                        {item?.assignAll
                          ? t("prepList.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <CheckPermission
                          permission={
                            constant.PERMISSIONS.CAN_PREP_LIST_OR_BATCH
                          }
                        >
                      <td>
                        <Link
                          to={`../add-prep-list?id=${item?._id}`}
                     
                          role="button"
                        >
                          <img src="./images/Edit.svg" alt="" />
                        </Link>

                        <img
                          src="./../images/Delete.svg"
                          alt="delete"
                          onClick={() => setShowDelete(item?._id)}
                          role="button"
                        />
                      </td>
                      </CheckPermission>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("prepList.noPrepList")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPages={data?.total_pages}
            total={data?.total}
            length={data?.datalists?.length}
          />
        </div>
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
       <FileModal
        show={show}
        handleClose={() => setShow(false)}
        handleAction={handleAction}
      />
    </div>
  );
};

export default PrepList;
